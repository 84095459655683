<template>
  <div>
    <!-- Filter -->
    <div class="d-flex justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama / NIP Karyawan"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-3" v-if="purpose !== 'modal-nurse'">
        <b-input-group>
          <treeselect
            v-model="filter.department_id"
            :multiple="false"
            :options="departments"
            @input="filterByDepartment" />
        </b-input-group>
      </div>
    </div>

    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive>
      <template #cell(photo)='data'>
        <div class="d-flex justify-content-center align-items-center image-container">
          <div style='height: 50px; width: 50px; position: relative'>
            <img
              class="image"
              :src="data.item.photo">
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <template v-if="purpose == 'master'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="
              $router.push({
                path: '/human-resource/employee/detail/' + data.item.id,
              })
            "
            v-b-tooltip.hover
            title="Detail"
            placement="bottom"><i class="fas fa-eye px-0"></i>
          </b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            @click="
              $router.push({
                path: '/human-resource/employee/edit/' + data.item.id,
              })
            "
            v-b-tooltip.hover
            title="Ubah"
            placement="bottom"
            v-if="manipulateBtn == true"><i class="fas fa-edit px-0"></i></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            @click="deleteData(data.item.id)"
            v-b-tooltip.hover
            title="Hapus"
            placement="bottom"
            v-if="manipulateBtn == true"><i class="fas fa-trash px-0"></i></b-button>
        </template>
        <template v-if="purpose == 'modal'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="
              chooseEmployee({
                id: data.item.id,
                name: data.item.name + ` (${data.item.phone})`,
              })
            ">
            Pilih
          </b-button>
        </template>

        <!-- <template v-if="purpose == 'modal' && !checkbox">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="chooseMedicine({id: data.item.id, name: data.item.name, sales_price: data.item.sales_price, stock: data.item.medicine_remaining_stock})"
          > Pilih </b-button>
        </template>
        <template v-if="purpose == 'modal' && checkbox">
          <b-form-checkbox
            :checked="data.item.checked"
            :id="`checkbox-pick-item-${data.item.id}`"
            :name="`checkbox-pick-item-${data.item.id}`"
            @change="chooseMedicine({id: data.item.id, name: data.item.name, sales_price: data.item.sales_price, stock: data.item.medicine_remaining_stock})"
          >
          </b-form-checkbox>
        </template> -->

        <template v-if="purpose == 'modal-nurse' && !checkbox || purpose == 'modal-omploop' && !checkbox || purpose == 'modal-nurse-action' && !checkbox">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="chooseEmployee({ id: data.item.id, name: data.item.name, position: data.item.position_name })">
            Pilih
          </b-button>
        </template>

        <template v-if="purpose == 'modal-nurse' && checkbox || purpose == 'modal-omploop' && checkbox || purpose == 'modal-nurse-action' && checkbox">
          <b-form-checkbox
            :checked="data.item.checked"
            :id="`checkbox-pick-item-${data.item.id}`"
            :name="`checkbox-pick-item-${data.item.id}`"
            @change="chooseEmployee({ id: data.item.id, name: data.item.name, position: data.item.position_name })">
          </b-form-checkbox>
        </template>


      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>
  </div>
</template>

<script>

import { debounce } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"

export default {
  props: {
    purpose: String,
    selectedItem: Array,
    checkbox: Boolean
  },

  data() {
    return {
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // acess management
      manipulateBtn: false,
      // Filter
      filter: {
        name: "",
        department_id: "",
      },
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: 'photo',
          label: 'Foto'
        },
        {
          key: "employee_number_id",
          label: "NIP",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "mobile_phone",
          label: "Nomor Telepon",
          sortable: true,
        },
        {
          key: "position_name",
          label: "Jabatan",
          sortable: true,
        },
        {
          key: "department_name",
          label: "Depertemen",
          sortable: true,
        },

        { key: "actions", label: "Aksi" },
      ],
      items: [],
      departments: [],
    }
  },

  methods: {
    async pagination() {
      let route = "employees"
      if (this.purpose === 'modal-nurse') {
        this.filter.department_id = 1
        route = "employees-for-medical-actions"
      }
      if (this.purpose === 'modal-nurse-action') {
        this.filter.department_id = 1
        route = "employees-for-medical-actions"
      }
      if (this.purpose === 'modal-omploop') {
        this.filter.department_id = 1
        route = "employees-for-medical-actions"
      }
      let filterParams = `&name=${this.filter.name}&department_id=${this.filter.department_id}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data;
      this.setCheckedItem()
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async getDepartmentOption() {
      let response = await module.setTreeSelect("departemens");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.departments = response.data;
        this.departments.unshift({
          label: "Cari Berdasar Departmen",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByName() {
      debounce(() => { this.pagination() }, 500)
    },

    async filterByDepartment(evt) {
      if (!evt) {
        this.filter.department_id = await ''
        this.pagination()
      } else {
        this.filter.department_id = await evt
        this.pagination()
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("employees/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    chooseEmployee(value) {
      this.$emit("chooseEmployee", value);
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {


        if (access_right[a] == "7002") {
          this.manipulateBtn = true
        }

      }
    },

    setCheckedItem() {
      let found
      this.items.forEach(value => {
        found = this.selectedItem.find(x => x.id == value.id)
        if (typeof found === 'undefined') {
          value.checked = false
        } else {
          value.checked = true
        }
      })
    },
  },

  mounted() {
    this.pagination();
    this.getDepartmentOption()
    this.setActiveMenu()
  },

  watch: {
    selectedItem: function (newVal) {
      this.setCheckedItem()
    }
  },
};
</script>

<style></style>